import * as React from "react";
import { useState } from "react";

import styled from "styled-components";

import { Layout } from "../components/layout";
import { ChooseSection } from "../components/sections/choose";
import { PaySection } from "../components/sections/pay";
import { UploadSection } from "../components/sections/upload";

const FlexContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    gap: 24px;
  }
`;

const FlexOne = styled.div`
  flex: 1;
`;

const ChooseSectionContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  @media (min-width: 768px) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const UploadSectionContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
`;

const PaySectionContainer = styled.div``;

const IndexPage = () => {
  const [shoppingCart, setShoppingCart] = useState<string[]>([]);
  const [globallyDisabled, setGloballyDisabled] = useState<boolean>(false);

  const addToShoppingCart = (s: string) =>
    setShoppingCart((current) => [...current, s]);
  return (
    <Layout>
      <FlexContainer>
        <FlexOne>
          <ChooseSectionContainer>
            <ChooseSection
              addToShoppingCart={addToShoppingCart}
              globallyDisabled={globallyDisabled}
            />
          </ChooseSectionContainer>
        </FlexOne>
        <FlexOne>
          <UploadSectionContainer>
            <UploadSection />
          </UploadSectionContainer>
          <PaySectionContainer>
            <PaySection
              shoppingCart={shoppingCart}
              setGloballyDisabled={setGloballyDisabled}
              globallyDisabled={globallyDisabled}
            />
          </PaySectionContainer>
        </FlexOne>
      </FlexContainer>
    </Layout>
  );
};

export default IndexPage;
