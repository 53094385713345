import * as React from "react";

import { SectionTitle } from "./common";

export const UploadSection = () => (
  <>
    <SectionTitle>2. Upload ton QR code</SectionTitle>
    <p>On ne va pas se mentir, ça ça ne marche pas encore.</p>
  </>
);
