import * as React from "react";

import styled from "styled-components";

export const SectionTitle = styled.h2`
  margin: 0 0 24px 0;
`;

export const Button = styled.button``;

export const Spinner = () => (
  <StyledSpinner viewBox="0 0 24 24">
    <circle
      className="path"
      cx="12"
      cy="12"
      r="10"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -12px 0 0 -12px;
  width: 12px;
  height: 12px;

  & .path {
    stroke: #444;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
