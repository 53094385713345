import * as React from "react";

import styled from "styled-components";

import { SectionTitle, Button, Spinner } from "./common";

const SERVER_URL =
  "https://europe-west3-disco-genius-321608.cloudfunctions.net/checkout";

const ListingAndButtons = ({
  shoppingCart,
  globallyDisabled,
  setGloballyDisabled,
}: {
  shoppingCart: string[];
  globallyDisabled: boolean;
  setGloballyDisabled: (gd: boolean) => void;
}) => {
  const counts = shoppingCart.reduce((acc, cur) => {
    const rv = { ...acc };
    rv[cur] = rv[cur] === undefined ? 1 : rv[cur] + 1;
    return rv;
  }, {});

  if (shoppingCart.length === 0) {
    return (
      <p>Tu n'as rien mis dans ton panier, du coup tu n'as rien à payer 🤷🏽‍♂️.</p>
    );
  }

  return (
    <>
      <p>
        On part donc sur {counts["CAP"] || 0} casquette(s) et{" "}
        {counts["T_SHIRT"] || 0} tshirt(s).
      </p>
      <Button
        onClick={async () => {
          setGloballyDisabled(true);
          const response = await fetch(SERVER_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: Object.entries(counts)
                .filter(([type, count]) => count !== 0)
                .map(([type, count]) => ({ type, quantity: count })),
            }),
          });
          const responseJson = await response.json();
          window.location.assign(responseJson.stripeUrl);
        }}
        disabled={globallyDisabled}
        style={globallyDisabled ? { paddingLeft: "18px" } : {}}
      >
        {globallyDisabled ? <Spinner /> : null} Payer
      </Button>
    </>
  );
};

export const PaySection = ({
  shoppingCart,
  globallyDisabled,
  setGloballyDisabled,
}: {
  shoppingCart: string[];
  globallyDisabled: boolean;
  setGloballyDisabled: (gd: boolean) => void;
}) => (
  <>
    <SectionTitle>3. Passe à la caisse</SectionTitle>
    <ListingAndButtons
      shoppingCart={shoppingCart}
      globallyDisabled={globallyDisabled}
      setGloballyDisabled={setGloballyDisabled}
    />
  </>
);
