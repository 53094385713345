import * as React from "react";

import styled from "styled-components";

import { SectionTitle, Button } from "./common";

const OneSwag = ({ addToShoppingCart, globallyDisabled }) => (
  <SwagContainer>
    <div>
      <img src="https://via.placeholder.com/80" />
    </div>
    <div>
      <h3 style={{ margin: "0 0 8px 0" }}>T-Shirt coucou - 35 €</h3>
      <p style={{ margin: "0 0 8px 0" }}>
        En coton bio d'alpagua tout doux de la ferme du bout là-bas.
      </p>
      <Button
        onClick={() => addToShoppingCart("T_SHIRT")}
        disabled={globallyDisabled}
      >
        Ajouter
      </Button>
    </div>
  </SwagContainer>
);

const TwoSwag = ({ addToShoppingCart, globallyDisabled }) => (
  <SwagContainer>
    <div>
      <img src="https://via.placeholder.com/80" />
    </div>
    <div>
      <h3 style={{ margin: "0 0 8px 0" }}>Casquette coucou - 20 €</h3>
      <p style={{ margin: "0 0 8px 0" }}>
        En coton bio d'alpagua tout doux de la ferme du bout là-bas. Elle
        protège y compris quand il pleut dans la file d'attente.
      </p>
      <p style={{ margin: "0 0 8px 0" }}>
        Ça c'est un test avec pas mal de contenu pour voir ce que ça donne avec
        l'image.
      </p>
      <p style={{ margin: "0 0 8px 0" }}>
        Bon, la casquette elle est probablement taille unique, mais on conseille
        quand même de la prendre une taille plus petit que d'habitude.
      </p>
      <Button
        onClick={() => addToShoppingCart("CAP")}
        disabled={globallyDisabled}
      >
        Ajouter
      </Button>
    </div>
  </SwagContainer>
);

const SwagContainer = styled.div`
  display: flex;
  gap: 16px;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ChooseSection = ({ addToShoppingCart, globallyDisabled }) => (
  <>
    <SectionTitle>1. Choisi ton swag</SectionTitle>
    <OneSwag
      addToShoppingCart={addToShoppingCart}
      globallyDisabled={globallyDisabled}
    />
    <TwoSwag
      addToShoppingCart={addToShoppingCart}
      globallyDisabled={globallyDisabled}
    />
  </>
);
